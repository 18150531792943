import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      name: "public-dashboard",
      component: () => import("@/view/pages/DashboardPublic.vue"),
    },
    {
      path: "/",
      // redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            middleware: 'auth'
          },
        },

        // HALAMAN STATISTIK
        {
          path: "/statistik/user",
          name: "statistik-user",
          component: () => import("@/view/pages/statistik/User.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        

        // HALAMAN BUG REPORT
        {
          path: "/bug-report/add",
          name: "bug-report-add",
          component: () => import("@/view/pages/bug-report/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/bug-report/histori",
          name: "bug-report-histori",
          component: () => import("@/view/pages/bug-report/histori/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/bug-report/histori/:idB",
          name: "bug-report-histori-view",
          component: () => import("@/view/pages/bug-report/histori/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN CUTTING HE
        {
          path: "/project/cutting-he/add",
          name: "project-cutting-he-add",
          component: () => import("@/view/pages/project/cutting-he/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/cutting-he/edit/:idBA",
          name: "project-cutting-he-edit",
          component: () => import("@/view/pages/project/cutting-he/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/cutting-he/histori",
          name: "project-cutting-he-histori",
          component: () => import("@/view/pages/project/cutting-he/histori/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/cutting-he/histori/:idBA",
          name: "project-cutting-he-histori-detail",
          component: () => import("@/view/pages/project/cutting-he/histori/Detail.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/cutting-he/target",
          name: "project-cutting-he-target",
          component: () => import("@/view/pages/project/cutting-he/target/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/cutting-he/target/:idS",
          name: "project-cutting-he-target-detail",
          component: () => import("@/view/pages/project/cutting-he/target/Detail.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/cutting-he/report-weekly",
          name: "project-cutting-he-report-weekly",
          component: () => import("@/view/pages/project/cutting-he/ReportWeekly.vue"),
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN AFKIR DINI
        {
          path: "/project/afkir-dini/add",
          name: "project-afkir-dini-add",
          component: () => import("@/view/pages/project/afkir-dini/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/afkir-dini/edit/:idBA",
          name: "project-afkir-dini-edit",
          component: () => import("@/view/pages/project/afkir-dini/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/afkir-dini/histori",
          name: "project-afkir-dini-histori",
          component: () => import("@/view/pages/project/afkir-dini/histori/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/afkir-dini/histori/:idBA",
          name: "project-afkir-dini-histori-detail",
          component: () => import("@/view/pages/project/afkir-dini/histori/Detail.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/afkir-dini/target",
          name: "project-afkir-dini-target",
          component: () => import("@/view/pages/project/afkir-dini/target/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/afkir-dini/target/:idS",
          name: "project-afkir-dini-target-detail",
          component: () => import("@/view/pages/project/afkir-dini/target/Detail.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/project/afkir-dini/report-weekly",
          name: "project-afkir-dini-report-weekly",
          component: () => import("@/view/pages/project/afkir-dini/ReportWeekly.vue"),
          meta: {
            middleware: 'auth'
          },
        },


        //PEUNGGASAN NASIONAL ----------------------
        // HALAMAN POPULASI
        {
          path: "/unggas-nasional/populasi",
          name: "unggas-nasional-populasi-farm-list",
          component: () => import("@/view/pages/nasional/populasi/Farm.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/populasi/:idF",
          name: "unggas-nasional-populasi-flock-list",
          component: () => import("@/view/pages/nasional/populasi/Flock.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

        //HALAMAN PRODUKSI
        {
          path: "/unggas-nasional/produksi",
          name: "unggas-nasional-produksi-hatchery-list",
          component: () => import("@/view/pages/nasional/produksi/Hatchery.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/produksi/:idH",
          name: "unggas-nasional-produksi-shr-list",
          component: () => import("@/view/pages/nasional/produksi/SHR.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

        //HALAMAN DISTRIBUSI
        {
          path: "/unggas-nasional/distribusi",
          name: "unggas-nasional-distribusi-hatchery-list",
          component: () => import("@/view/pages/nasional/distribusi/Hatchery.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/distribusi/:idH",
          name: "unggas-nasional-distribusi-tabel-list",
          component: () => import("@/view/pages/nasional/distribusi/List.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/distribusi/riwayat/:idH",
          name: "unggas-nasional-distribusi-tabel-riwayat",
          component: () => import("@/view/pages/nasional/distribusi/form/_Riwayat.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/distribusi/send-doc/add/:idH",
          name: "unggas-nasional-distribusi-add-send-doc",
          component: () => import("@/view/pages/nasional/distribusi/form/send-doc/_Index.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/distribusi/:idH/:idD",
          name: "unggas-nasional-distribusi-edit-send-doc",
          component: () => import("@/view/pages/nasional/distribusi/form/_Detail.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

        //HALAMAN REPORT
        {
          path: "/unggas-nasional/report/weekly",
          name: "unggas-nasional-report-weekly",
          component: () => import("@/view/pages/nasional/report/Weekly.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/report/tracking",
          name: "unggas-nasional-report-tracking",
          component: () => import("@/view/pages/nasional/report/Tracking.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/report/existing-populasi",
          name: "unggas-nasional-report-existing-populasi",
          component: () => import("@/view/pages/nasional/report/ExistingPopulasi.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-nasional/report/status-input",
          name: "unggas-nasional-report-status-input",
          component: () => import("@/view/pages/nasional/report/StatusInput.vue"),
          meta: {
            middleware: 'auth'
          },
        },


        //UNGGAS KOMERSIL ----------------------
        // HALAMAN POPULASI
        {
          path: "/unggas-komersil/populasi",
          name: "unggas-komersil-populasi-farm-list",
          component: () => import("@/view/pages/nasional/populasi/Farm.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-komersil/populasi/:idF",
          name: "unggas-komersil-populasi-flock-list",
          component: () => import("@/view/pages/nasional/populasi/Flock.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

        //HALAMAN DISTRIBUSI
        {
          path: "/unggas-komersil/distribusi",
          name: "unggas-komersil-distribusi-hatchery-list",
          component: () => import("@/view/pages/nasional/distribusi/Hatchery.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-komersil/distribusi/:idH",
          name: "unggas-komersil-distribusi-tabel-list",
          component: () => import("@/view/pages/nasional/distribusi/List.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-komersil/distribusi/riwayat/:idH",
          name: "unggas-komersil-distribusi-tabel-riwayat",
          component: () => import("@/view/pages/nasional/distribusi/form/_Riwayat.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-komersil/distribusi/send-doc/add/:idH",
          name: "unggas-komersil-distribusi-add-send-doc",
          component: () => import("@/view/pages/nasional/distribusi/form/send-doc/_Index.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-komersil/distribusi/:idH/:idD",
          name: "unggas-komersil-distribusi-edit-send-doc",
          component: () => import("@/view/pages/nasional/distribusi/form/_Detail.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

        //HALAMAN REPORT
        {
          path: "/unggas-komersil/report/weekly",
          name: "unggas-komersil-report-weekly",
          component: () => import("@/view/pages/nasional/report/Weekly.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/unggas-komersil/report/tracking",
          name: "unggas-komersil-report-tracking",
          component: () => import("@/view/pages/nasional/report/Tracking.vue"),
          meta: {
            middleware: 'auth'
          },
        },



        //MASTER DATA ----------------------
        // HALAMAN FARM
        {
          path: "/master-data/farm",
          name: "master-data-farm-list",
          component: () => import("@/view/pages/master-data/farm/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/farm/add",
          name: "master-data-farm-add",
          component: () => import("@/view/pages/master-data/farm/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/farm/edit/:idF",
          name: "master-data-farm-edit",
          component: () => import("@/view/pages/master-data/farm/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/farm/view/:idF",
          name: "master-data-farm-view",
          component: () => import("@/view/pages/master-data/farm/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN HATCHERY
        {
          path: "/master-data/hatchery",
          name: "master-data-hatchery-list",
          component: () => import("@/view/pages/master-data/hatchery/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/hatchery/add",
          name: "master-data-hatchery-add",
          component: () => import("@/view/pages/master-data/hatchery/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/hatchery/edit/:idH",
          name: "master-data-hatchery-edit",
          component: () => import("@/view/pages/master-data/hatchery/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/hatchery/view/:idH",
          name: "master-data-hatchery-view",
          component: () => import("@/view/pages/master-data/hatchery/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN SURAT EDARAN
        {
          path: "/master-data/surat-edarans",
          name: "master-data-surat-edaran-list",
          component: () => import("@/view/pages/master-data/surat-edaran/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/surat-edaran/add",
          name: "master-data-surat-edaran-add",
          component: () => import("@/view/pages/master-data/surat-edaran/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/surat-edarans/:idSE",
          name: "master-data-surat-edaran-edit",
          component: () => import("@/view/pages/master-data/surat-edaran/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN PETERNAK
        {
          path: "/master-data/peternak",
          name: "master-data-peternak-list",
          component: () => import("@/view/pages/master-data/peternak/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/peternak/add",
          name: "master-data-peternak-add",
          component: () => import("@/view/pages/master-data/peternak/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/peternak/edit/:idP",
          name: "master-data-peternak-edit",
          component: () => import("@/view/pages/master-data/peternak/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/peternak/view/:idP",
          name: "master-data-peternak-view",
          component: () => import("@/view/pages/master-data/peternak/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/peternak/:idP/farm/edit/:idF",
          name: "master-data-peternak-farm-edit",
          component: () => import("@/view/pages/master-data/peternak/farm/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        

        // HALAMAN WILAYAH
        {
          path: "/master-data/wilayah",
          name: "master-data-wilayah-list",
          component: () => import("@/view/pages/master-data/wilayah/Utama.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/wilayah/provinsi/add",
          name: "master-data-wilayah-provinsi-add",
          component: () => import("@/view/pages/master-data/wilayah/provinsi/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/wilayah/provinsi/edit/:idWL",
          name: "master-data-wilayah-provinsi-edit",
          component: () => import("@/view/pages/master-data/wilayah/provinsi/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/wilayah/kabupaten/add",
          name: "master-data-wilayah-kabupaten-add",
          component: () => import("@/view/pages/master-data/wilayah/kabupaten/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/wilayah/kabupaten/edit/:idP/:idK",
          name: "master-data-wilayah-kabupaten-edit",
          component: () => import("@/view/pages/master-data/wilayah/kabupaten/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN USER
        {
          path: "/master-data/user",
          name: "master-data-user-list",
          component: () => import("@/view/pages/master-data/user/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/user/add",
          name: "master-data-user-add",
          component: () => import("@/view/pages/master-data/user/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/user/edit/:idUS",
          name: "master-data-user-edit",
          component: () => import("@/view/pages/master-data/user/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN ROLE
        {
          path: "/master-data/role",
          name: "master-data-role-list",
          component: () => import("@/view/pages/master-data/role/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/role/add",
          name: "master-data-role-add",
          component: () => import("@/view/pages/master-data/role/Add.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          path: "/master-data/role/edit/:idRL",
          name: "master-data-role-edit",
          component: () => import("@/view/pages/master-data/role/Edit.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // HALAMAN PROFILE
        {
          name: "profile",
          path: "/profile",
          component: () => import("@/view/pages/auth/Profile"),
          meta: {
            middleware: 'auth'
          },
        },
      ]
    },


    //STATISTIK PUBLIC --------------------
    {
      path: "/public",
      redirect: "/public/statistik",
      component: () => import("@/view/pages/statistik/Public.vue"),
      children: [
        {
          path: "/public/statistik",
          name: "public-statistik",
          component: () => import("@/view/pages/statistik/_public/Utama.vue")
        },
      ]
    },



    //VERIFIKASI DATA ----------------------
    {
      path: "/",
      component: () => import("@/view/pages/verifikasi/Utama.vue"),
      children: [
        {
          name: "verifikasi",
          path: "/verifikasi",
          component: () => import("@/view/pages/verifikasi/Dashboard.vue"),
          meta: {
            middleware: 'auth'
          },
        },

        //PERUNGGASAN NASIONAL
        {
          name: "verifikasi-nasional-populasi-chickin",
          path: "/verifikasi/nasional/populasi/chickin",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/chickin/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-chickin-view",
          path: "/verifikasi/nasional/populasi/chickin/:idP",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/chickin/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-deplesi",
          path: "/verifikasi/nasional/populasi/deplesi",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/deplesi/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-deplesi-view",
          path: "/verifikasi/nasional/populasi/deplesi/:idP",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/deplesi/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-feedintake",
          path: "/verifikasi/nasional/populasi/feedintake",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/feedintake/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-feedintake-view",
          path: "/verifikasi/nasional/populasi/feedintake/:idP",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/feedintake/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-transfer",
          path: "/verifikasi/nasional/populasi/transfer",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/transfer/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-transfer-view",
          path: "/verifikasi/nasional/populasi/transfer/:idP",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/transfer/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-produksi-egg",
          path: "/verifikasi/nasional/populasi/produksi-egg",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/produksi-egg/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-produksi-egg-view",
          path: "/verifikasi/nasional/populasi/produksi-egg/:idP",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/produksi-egg/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-afkir",
          path: "/verifikasi/nasional/populasi/afkir",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/afkir/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-populasi-afkir-view",
          path: "/verifikasi/nasional/populasi/afkir/:idP",
          component: () => import("@/view/pages/verifikasi/nasional/populasi/afkir/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-produksi-setting-he",
          path: "/verifikasi/nasional/produksi/setting-he",
          component: () => import("@/view/pages/verifikasi/nasional/produksi/setting-he/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-produksi-setting-he-view",
          path: "/verifikasi/nasional/produksi/setting-he/:idS",
          component: () => import("@/view/pages/verifikasi/nasional/produksi/setting-he/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-produksi-pullchick",
          path: "/verifikasi/nasional/produksi/pullchick",
          component: () => import("@/view/pages/verifikasi/nasional/produksi/pullchick/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-produksi-pullchick-view",
          path: "/verifikasi/nasional/produksi/pullchick/:idS",
          component: () => import("@/view/pages/verifikasi/nasional/produksi/pullchick/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-distribusi-send-doc",
          path: "/verifikasi/nasional/distribusi/send-doc",
          component: () => import("@/view/pages/verifikasi/nasional/distribusi/send-doc/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-nasional-distribusi-send-doc-view",
          path: "/verifikasi/nasional/distribusi/send-doc/:idS",
          component: () => import("@/view/pages/verifikasi/nasional/distribusi/send-doc/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // PROJECT
        {
          name: "verifikasi-data-cutting-he",
          path: "/verifikasi/project/cutting-he",
          component: () => import("@/view/pages/verifikasi/project/cutting-he/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-data-cutting-he-view",
          path: "/verifikasi/project/cutting-he/:idS",
          component: () => import("@/view/pages/verifikasi/project/cutting-he/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-data-afkir-dini",
          path: "/verifikasi/project/afkir-dini",
          component: () => import("@/view/pages/verifikasi/project/afkir-dini/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-data-afkir-dini-view",
          path: "/verifikasi/project/afkir-dini/:idS",
          component: () => import("@/view/pages/verifikasi/project/afkir-dini/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },


        // MASTER DATA
        {
          name: "verifikasi-master-data-farm",
          path: "/verifikasi/master-data/farm",
          component: () => import("@/view/pages/verifikasi/master-data/farm/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-farm-view",
          path: "/verifikasi/master-data/farm/:idF",
          component: () => import("@/view/pages/verifikasi/master-data/farm/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-hatchery",
          path: "/verifikasi/master-data/hatchery",
          component: () => import("@/view/pages/verifikasi/master-data/hatchery/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-hatchery-view",
          path: "/verifikasi/master-data/hatchery/:idH",
          component: () => import("@/view/pages/verifikasi/master-data/hatchery/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-surat-edaran",
          path: "/verifikasi/master-data/surat-edaran",
          component: () => import("@/view/pages/verifikasi/master-data/surat-edaran/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-surat-edaran-view",
          path: "/verifikasi/master-data/surat-edaran/:idS",
          component: () => import("@/view/pages/verifikasi/master-data/surat-edaran/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-peternak",
          path: "/verifikasi/master-data/peternak",
          component: () => import("@/view/pages/verifikasi/master-data/peternak/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-peternak-view",
          path: "/verifikasi/master-data/peternak/:idP",
          component: () => import("@/view/pages/verifikasi/master-data/peternak/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-wilayah",
          path: "/verifikasi/master-data/wilayah",
          component: () => import("@/view/pages/verifikasi/master-data/wilayah/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-user",
          path: "/verifikasi/master-data/user",
          component: () => import("@/view/pages/verifikasi/master-data/user/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-user-view",
          path: "/verifikasi/master-data/user/:idU",
          component: () => import("@/view/pages/verifikasi/master-data/user/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-role",
          path: "/verifikasi/master-data/role",
          component: () => import("@/view/pages/verifikasi/master-data/role/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-master-data-role-view",
          path: "/verifikasi/master-data/role/:idR",
          component: () => import("@/view/pages/verifikasi/master-data/role/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

        
        //PERBAIAKN PERUNGGASAN NASIONAL
        {
          name: "verifikasi-perbaikan-nasional-populasi-chickin",
          path: "/verifikasi/perbaikan/nasional/populasi/chickin",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/chickin/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-chickin-view",
          path: "/verifikasi/perbaikan/nasional/populasi/chickin/:idP",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/chickin/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-deplesi",
          path: "/verifikasi/perbaikan/nasional/populasi/deplesi",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/deplesi/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-deplesi-view",
          path: "/verifikasi/perbaikan/nasional/populasi/deplesi/:idP",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/deplesi/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-feedintake",
          path: "/verifikasi/perbaikan/nasional/populasi/feedintake",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/feedintake/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-feedintake-view",
          path: "/verifikasi/perbaikan/nasional/populasi/feedintake/:idP",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/feedintake/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-transfer",
          path: "/verifikasi/perbaikan/nasional/populasi/transfer",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/transfer/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-transfer-view",
          path: "/verifikasi/perbaikan/nasional/populasi/transfer/:idP",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/transfer/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-produksi-egg",
          path: "/verifikasi/perbaikan/nasional/populasi/produksi-egg",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/produksi-egg/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-produksi-egg-view",
          path: "/verifikasi/perbaikan/nasional/populasi/produksi-egg/:idP",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/produksi-egg/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-afkir",
          path: "/verifikasi/perbaikan/nasional/populasi/afkir",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/afkir/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-populasi-afkir-view",
          path: "/verifikasi/perbaikan/nasional/populasi/afkir/:idP",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/populasi/afkir/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-produksi-setting-he",
          path: "/verifikasi/perbaikan/nasional/produksi/setting-he",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/produksi/setting-he/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-produksi-setting-he-view",
          path: "/verifikasi/perbaikan/nasional/produksi/setting-he/:idS",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/produksi/setting-he/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-produksi-pullchick",
          path: "/verifikasi/perbaikan/nasional/produksi/pullchick",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/produksi/pullchick/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-produksi-pullchick-view",
          path: "/verifikasi/perbaikan/nasional/produksi/pullchick/:idS",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/produksi/pullchick/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-distribusi-send-doc",
          path: "/verifikasi/perbaikan/nasional/distribusi/send-doc",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/distribusi/send-doc/List.vue"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "verifikasi-perbaikan-nasional-distribusi-send-doc-view",
          path: "/verifikasi/perbaikan/nasional/distribusi/send-doc/:idS",
          component: () => import("@/view/pages/verifikasi/perbaikan/nasional/distribusi/send-doc/View.vue"),
          props: true,
          meta: {
            middleware: 'auth'
          },
        },

      ]
    },


    //AUTHENTICATION USER ----------------------
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "auth-login",
          path: "/auth/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "auth-callback",
          path: "/auth/callback",
          component: () => import("@/view/pages/auth/Callback")
        },
        {
          name: "auth-forgot-password",
          path: "/auth/forgot-password",
          component: () => import("@/view/pages/auth/ForgotPassword")
        },
        {
          name: "auth-select-company",
          path: "/auth/select-company",
          component: () => import("@/view/pages/auth/SelectCompany"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "auth-email-verified",
          path: "/auth/email-verified",
          component: () => import("@/view/pages/auth/EmailVerified"),
          meta: {
            middleware: 'auth'
          },
        },
        {
          name: "auth-change-password",
          path: "/auth/change-password",
          component: () => import("@/view/pages/auth/ChangePassword"),
          meta: {
            middleware: 'auth'
          },
        },
      ]
    },
    
    {
      path: "*",
      name: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/404.vue")
    }
  ]
});
