import ApiService from "@/core/services/api.service";
import ErrorService from "@/core/services/error.service";

// action types
export const GET_COUNT = "getCount";
export const DEL_COUNT = "delCount";
export const UPDATE_COUNT = "updateCount";

// mutation types
export const SET_ERROR = "setError";
export const SET_COUNT = "setCount";
export const PURGE_COUNT = "purgeCount";

export default {
  state: {
    errors: null,
    count: {
      nasional: {
        populasi: {
          chickin: 0,
          deplesi: 0,
          feedintake: 0,
          transfer: 0,
          produksi_egg: 0,
          afkir: 0,
        },
        produksi: {
          setting_he: 0,
          pullchick: 0
        },
        distribusi: {
          send_doc: 0
        },
      },
      project: {
        cuttinghe: 0,
        afkirdini: 0,
      },
      master: {
        farm: 0,
        hatchery: 0,
        suratedaran: 0,
        peternak: 0,
        user: 0,
        role: 0,
      },
      perbaikan: {
        nasional: {
          populasi: {
            chickin: 0,
            deplesi: 0,
            feedintake: 0,
            transfer: 0,
            produksi_egg: 0,
            afkir: 0,
          },
          produksi: {
            setting_he: 0,
            pullchick: 0
          },
          distribusi: {
            send_doc: 0
          },
        },
      }
    },
  },
  
  getters: {
    currentCount(state) {
      return state.count;
    },
  },
  
  actions: {
    [GET_COUNT](context, credentials) {
      return new Promise(resolve => {
        ApiService.setHeader();
        ApiService.query("v2/verifikasi/total", { params: credentials })
          .then(( res ) => {
            if (!res.data.status) {
              ErrorService.message(res.data)
              context.commit(SET_ERROR, res.data.message)
              return resolve();
            }
            context.commit(SET_COUNT, res.data.data);
            return resolve(res);
          })
          .catch((err) => {
            context.commit(SET_ERROR, err.response.data.message)
            ErrorService.status(err)
          });
      });
    },
    [DEL_COUNT](context) {
      return new Promise((resolve) => {
        context.commit(PURGE_COUNT);
        return resolve();
      })
    },
    [UPDATE_COUNT](context, payload) {
      return new Promise((resolve) => {
        context.commit(SET_COUNT, payload);
        return resolve();
      })
    }
  },
  
  mutations: {
    [SET_ERROR](state, error) {
      state.count = {
        nasional: {
          populasi: {
            chickin: 0,
            deplesi: 0,
            feedintake: 0,
            transfer: 0,
            produksi_egg: 0,
            afkir: 0,
          },
          produksi: {
            setting_he: 0,
            pullchick: 0
          },
          distribusi: {
            send_doc: 0
          },
        },
        project: {
          cuttinghe: 0,
          afkirdini: 0,
        },
        master: {
          farm: 0,
          hatchery: 0,
          suratedaran: 0,
          peternak: 0,
          user: 0,
          role: 0,
        },
        perbaikan: {
          nasional: {
            populasi: {
              chickin: 0,
              deplesi: 0,
              feedintake: 0,
              transfer: 0,
              produksi_egg: 0,
              afkir: 0,
            },
            produksi: {
              setting_he: 0,
              pullchick: 0
            },
            distribusi: {
              send_doc: 0
            },
          },
        }
      };
      state.errors = error;
    },
    [SET_COUNT](state, data) {
      state.count = data;
      state.errors = null;
    },
    [PURGE_COUNT](state) {
      state.count = {
        nasional: {
          populasi: {
            chickin: 0,
            deplesi: 0,
            feedintake: 0,
            transfer: 0,
            produksi_egg: 0,
            afkir: 0,
          },
          produksi: {
            setting_he: 0,
            pullchick: 0
          },
          distribusi: {
            send_doc: 0
          },
        },
        project: {
          cuttinghe: 0,
          afkirdini: 0,
        },
        master: {
          farm: 0,
          hatchery: 0,
          suratedaran: 0,
          peternak: 0,
          user: 0,
          role: 0,
        },
        perbaikan: {
          nasional: {
            populasi: {
              chickin: 0,
              deplesi: 0,
              feedintake: 0,
              transfer: 0,
              produksi_egg: 0,
              afkir: 0,
            },
            produksi: {
              setting_he: 0,
              pullchick: 0
            },
            distribusi: {
              send_doc: 0
            },
          },
        }
      };
      state.errors = null;
    },
  }
};
